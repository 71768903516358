import { render, staticRenderFns } from "./lang_detail.vue?vue&type=template&id=170b87ab&scoped=true&"
import script from "./lang_detail.vue?vue&type=script&lang=ts&"
export * from "./lang_detail.vue?vue&type=script&lang=ts&"
import style0 from "./lang_detail.vue?vue&type=style&index=0&id=170b87ab&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "170b87ab",
  null
  
)

export default component.exports