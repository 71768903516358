
import { apiGetlang, langCodeLists, langCodeDel } from "@/api/setting";
import { Component, Prop, Vue } from "vue-property-decorator";
import lsPagination from "@/components/ls-pagination.vue";
import LsDialog from "@/components/ls-dialog.vue";
import { RequestPaging } from "@/utils/util";
@Component({
  components: {
    lsPagination,
    LsDialog,
  },
})
export default class SetMealList extends Vue {
  langCodeLists = langCodeLists;
  pager: RequestPaging = new RequestPaging();
  searchObj = {
    remarks: "",
    type_id: null,
  };
  list = [];
  // 获取语言列表
  getLangList() {
    apiGetlang({})
      .then((res: any) => {
        this.list = res;
        this.searchObj.type_id = res[0].id;
        this.getStatement();
      })
      .catch(() => {
        this.$message.error("获取数据失败，请重试！");
      });
  }
  // 获取语句列表
  getStatement(): void {
    this.pager.request({
      callback: langCodeLists,
      params: {
        ...this.searchObj,
      },
    });
  }

  // 删除语句
  async onSetMealDelete(row: any): Promise<void> {
    await langCodeDel({ id: row.id });
    this.$message.success("删除成功");
    this.getStatement();
  }

  // 重置搜索
  reset(): void {
    Object.keys(this.searchObj).map((key) => {
      this.$set(this.searchObj, key, "");
    });
    this.getStatement();
  }

  // 编辑
  toSetMealEdit(row: any): void {
    this.$router.push({
      path: "/setting/platform/lang_detail_edit",
      query: {
        id: row.id,
        code: row.code,
      },
    });
  }
  async mounted() {
    await this.getLangList();
  }
}
